.footer {
  background-color: #0a1e40;
  color: #edbf41;
  padding: 8px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-link a, .footer-phone {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #edbf41;
  gap: 0.5rem;
}

.footer-link:hover, .footer-phone:hover {
  opacity: 0.8;
}

.icon {
  width: 24px;
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) { /* Adjusts for tablets */
  .footer {
    justify-content: space-between; /* Spreads items to both ends */
    padding: 8px 20px; /* Increase padding for touch targets */
  }

  .icon {
    width: 20px; /* Optionally make icons smaller */
  }
}

@media (max-width: 480px) { /* Adjusts for mobile phones */
  .footer {
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the start */
    padding: 16px 20px; /* More padding for smaller screens */
  }

  .footer-link, .footer-phone {
    width: 100%; /* Makes the link take the full width */
    justify-content: flex-start; /* Aligns content to the left */
    padding: 8px 0; /* Adds vertical padding for tap targets */
  }

  .footer-link a, .footer-phone a {
    gap: 10px; /* Increase gap on smaller screens for better readability */
  }

  .footer-link img, .footer-phone img {
    width: 20px; /* Adjust the icon size as needed */
  }
}
