 .parent {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

/*.img_container {
  background-image: URL('C:\Users\fidol\OneDrive\Desktop\Vikram\Farmhero\FarmHero Final\Frontend\public\sidara-keo-W8L7WKSgPhA-unsplash.jpg');
  background-size:contain;
  background-repeat: no-repeat;
  width: 45%;
  height: 603px;
}



@media only screen and (max-width: 768px) {
  .img_container {
    background-size: cover;
    height: 545px;
  }} */ 

  /* Add this to your Home.css file or create a new CSS file for the Home component */

.container1 {
  max-width: 1000px; /* Adjust the max-width as needed */
  margin: 5px auto; /* Center the container horizontally */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: auto;
  align-items: center;
  /* Hide any overflow content */
}

.dev-container {
  width: 500px;
  height: 500px;
  border: 2px solid #333;
  overflow: hidden; /* Hide any overflow content */
}
.dev-inner {
  width: 100%;
  height: 100%;
}

/* Ensure the container takes at least the full viewport height */
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
}

/* This will make container1 take up all available vertical space */
.container1 {
  flex: 1; 
}

/* Add this to your existing CSS or create a new CSS file */

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

