/* Reset some default styles */
body,
h1,
p,
input,
button {
  margin: 0;
  padding: 0;
  align-items: center !important;
}


body {
  font-family: Arial, sans-serif;
  background-color: #D0D0D0;
}

/* Main container styling */
.App {
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
}

/* Label styling */
label {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  text-align: left;
  font-size: large;
}

/* Input styling */
input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Radio button group styling */
.radio-group {
  margin-bottom: 10px;
}

input[type=radio] {
  margin: 10px;
}

/* Error message styling */
p.error-message {
  color: #edbf41;
  margin-top: 10px;
}

/* Bottom section styling */
.bottom-section {
  margin-top: 20px;
  text-align: center;
}

/* Button styling */
button.Apple {
  background-color: #0a1e40;
  color: #edbf41;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button.Apple:hover {
  background-color: #1b50ac;
  color: #edbf41;
}

/* "Register" link styling */
a.Apple {
  text-decoration: none;
  color: #edbf41;
  margin-top: 10px;
  display: inline-block;
}

a.Apple:hover {
  text-decoration: underline;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .App {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  input {
    font-size: 12px;
  }
}

/* Add this to your CSS file or style section */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
}

.modal-content {
  text-align: center;
}

/* Style your buttons as needed */
.modal-content button {
  margin: 5px;
  padding: 10px;
}

.password-instruction {
  opacity: 0.7;
  /* Adjust the opacity value as needed */
  /* Add any other styling you want for the password instruction text */
}