/* Container holding the form */
.container {
  margin: 50px auto;
  max-width: 800px;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #cccccc;
}

/* Style the input field */
input[type=text], input[type=number], input[type=date], select {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Style the submit button */
button {
  background-color: #0a1e40;
  color: #edbf41;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

button:hover {
  opacity:1;
}

/* Add a background color and some padding around the form */
.container {
  background-color: #f7f7f7;
  padding: 20px;
}

/* Style the submit button */
/* Add some hover effects to buttons */
button:hover {
  opacity:1;
}

/* Add a bottom border to all form inputs */
input[type=text], input[type=number], input[type=date], select {
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
}

/* Style the select field */
select {
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
}

/* Add a background color to dropdown boxes */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
h2 {
  text-align: center;
  padding-top: 10px;
}

.radio-container input[type="radio"] {
  margin-right: 10px; /* Adjust the value to control the space */
}