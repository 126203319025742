/* ImageSlider.css */

/* Optional: Customize the carousel container styles */
#carouselExampleFade {
  max-width: auto; /* Adjust as needed */
  margin: auto; /* Center the carousel */
  background-color: transparent;
  position: relative;
}

/* Optional: Customize the carousel item styles */
.carousel-item img {
  border-radius: 20px; /* Add rounded corners to images */
  max-height: auto; /* Adjust as needed */
  object-fit: cover;
  background-color: transparent;
  /* width: 500px;
  height: 500px;Ensure images cover the entire container */
}

/* Optional: Customize the carousel control button styles */
.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  background-color: transparent !important; /* Adjust the button size */
}

/* Optional: Customize the carousel control icon styles */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: transparent; /* Adjust the color */
}

/* Optional: Customize the visually hidden class */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  background-color: transparent;
}

/* Optional: Customize the carousel caption styles */
.carousel-caption {
  font-weight: bold; /* Use font-weight instead of font property */
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  color: #edbf41; /* Text color */
  background-color: rgba(10, 30, 64, 0.75); /* Black overlay with 50% opacity */
  padding: 15px; /* Adjust as needed */
  border-radius: 5px; /* Optional: Add rounded corners to the overlay */
}

.carousel-item-active img{
  width:50%;
}

.d-block-w-100{
  padding: auto;
}

.img-d-block-w-100{
  align-items: center;
}

@media screen and (max-width: 600px) {
  .carousel-item img {
    max-width: auto; /* Adjust as needed */
    margin: auto; /* Center the carousel */
    background-color: transparent;
    position: relative;
    width: 700px;
    height: 500px;

  }
}