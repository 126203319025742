/* Add these to your existing CSS */

/* Base styles */
.filter-buttons {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.filter-button {
  margin-right: 10px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #0a1e40;
  color: #edbf41;
  border: none;
  border-radius: 4px;
}

.filter-button.active {
  background-color: #edbf41;
  color: #0a1e40;
}

.Bookingmodal-body {
  padding: 5px;
  margin: 2px;
}

.yellow {
  background-color: #edbf41;
}

.gray {
  background-color: #888;
}

/* Responsive adjustments */
@media screen and (max-width: 600px) {
  .filter-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .filter-button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .Bookingmodal-content {
    width: 90%;
  }

  .paymentmodal-content {
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .Bookingmodal-content,
  .paymentmodal-content {
    width: 95%;
  }
}

/* Style for modal */
.Bookingmodal, .paymentmodal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

/* Modal content */
.Bookingmodal-content, .paymentmodal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px; /* Adjust as needed */
}

/* Styles for paymentmodal-body buttons */
.paymentmodal-body div {
  margin-top: 10px;
}

.paymentmodal-body button {
  background-color: #0a1e40;
  color: #edbf41;
  padding: 10px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
}

.paymentmodal-body button:hover {
  background-color: #edbf41;
  color: #0a1e40;
}


/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .filter-buttons {
    flex-direction: column;
  }

  .filter-button {
    margin-bottom: 10px;
  }

  .table {
    display: block;
    overflow-x: auto;
  }

  th, td {
    min-width: 100px;
  }
}

/* Additional styling for the modals */
.Bookingmodal, .paymentmodal {
  display:flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.Bookingmodal-content, .paymentmodal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Additional styling for mobile screens */
@media (max-width: 480px) {
  .Bookingmodal-content, .paymentmodal-content {
    width: 90%;
  }
}

.h1{
  align-items: center;
  align-self: start;
}

.paymentmodal-header, .Bookingmodal-header{
 color: #0a1e40 !important;
  padding: 8px;
  padding-right: 10px;
  text-align: right;
  width: 100%;
}
.paymentmodal-footer, .Bookingmodal-footer{
  background-color: #0a1e40 !important;
  color: #edbf41 !important;
  padding: 8px;
  padding-right: 10px;
  width: 100%;
}
  