/* BookNow.css */

.button-container-BookNow {
  justify-content: center;
  position: relative;
  display: flex;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.button-container-BookNow button {
  background-color: #0a1e40;
  color: #edbf41;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 0 10px; /* Add margin between buttons */
  cursor: pointer;
  border: none;
}

.button-container-BookNow button:hover {
  background-color: #edbf41;
  color: #0a1e40;
}
  
  /* Responsive adjustments */
  @media screen and (max-width: 600px) {
    .button-container-BookNow button {
      padding: 10px 10px;
      margin: 10px 5px ;
      /* vertical-align: middle; */
      position: relative;
      bottom: 10px;
    }
  }

  @media screen and (max-width: 1011px) {
    .button-container-BookNow button {
      padding: 10px 10px;
      margin: 10px 5px ;
      /* vertical-align: middle; */
      position: relative;
      bottom: 12px;
    }
  }
  
